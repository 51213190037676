<template>
  <div class="page-header ph-bg" :style = "{backgroundImage: 'url(' + require('@/assets/images/page-header.jpg') + ')'}">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div class="page-section">
            <h1 class="page-title">About Us</h1>
            <p class="page-text">
              Shine Cleaning Website Template is committed to providing high
              quality cleaning services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHero",
};
</script>

<style scoped>


</style>