<template>
    <div class="space-small bg-default">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <div class="counter-block">
                        <div class="counter-content">
                            <h1 class="counter-title">3000</h1>
                            <span class="counter-text">Project Completed</span> </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <div class="counter-block">
                        <div class="counter-content">
                            <h1 class="counter-title">1840</h1>
                            <span class="counter-text">Satisfied Customers</span> </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <div class="counter-block">
                        <div class="counter-content">
                            <h1 class="counter-title">2474</h1>
                            <span class="counter-text">Cleaning Advices</span> </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <div class="counter-block">
                        <div class="counter-content">
                            <h1 class="counter-title">423</h1>
                            <span class="counter-text">Staff Across The World</span> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "Accomplishments"
}
</script>