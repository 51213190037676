<template>
  <div class="slider">
    <!-- <img :src="imgslider1" alt="" width="1900" height="559" /> -->
    <div class="owl-carousel slider">
      <div class="item">
        <div class="slider-img">
          <img :src="imgslider1" alt="" width="1900" height="559" />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div class="slider-captions">
                <h1 class="slider-title">Domestic &amp; Commercial Cleaning</h1>
                <p class="slider-text hidden-xs">
                  Cleaning Services we are passionate about providing a flexible
                  service.
                </p>
                <a
                  href="about.html"
                  class="btn btn-default btn-lg hidden-sm hidden-xs"
                  >Click Here for a Free Estimate</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="slider-img">
          <img :src="imgslider2" alt="" width="1900" height="559" />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div class="slider-captions">
                <h1 class="slider-title">
                  We help you to keep your place clean
                </h1>
                <p class="slider-text hidden-xs">
                  We use specialize and quality equipment tools for cleaning !
                </p>
                <a href="#" class="btn btn-default btn-lg hidden-sm hidden-xs"
                  >meet team</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="slider-img">
          <img :src="imgslider3" alt="" width="1900" height="559" />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div class="slider-captions">
                <h1 class="slider-title">Quality work with Affordable price</h1>
                <p class="slider-text hidden-xs">
                  We brings professional cleaning services right to your home.
                </p>
                <a href="#" class="btn btn-default btn-lg hidden-sm hidden-xs"
                  >view services</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  data: () =>{
      return {
          imgslider1: require("../assets/images/slider_one.png"),
          imgslider2: require("../assets/images/slider_one.png"),
          imgslider3: require("../assets/images/slider_one.png")
      }
  },
  mounted(){
    // console.log("slider function", window.showSlider)
    window.showSlider()
  }
};
</script>