<template>
  <div class="space-medium">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="section-title">
            <!-- section title start-->
            <h1>Professional Cleaning Services</h1>
            <p>We Provided Quality Cleaning Services.</p>
          </div>
          <!-- /.section title start-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img1" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Factory, Complex, Office & Buildings</a></h3>
              <p>
                We provide unmatched excellence in cleaning building of all
                types, including but not limited to Factories, Complexes and
                Office buildings
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img2" alt="" class="img-responsive" /> </a>
            </div>
            <div class="service-content">
              <h3><a href="#">Contract Based Cleaning</a></h3>
              <p>
                We offer very pocket friendly and flexible contracts to our
                clients. Please do not hesitate to have us keep your place,
                business, property clean always.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img3" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Sofa Set, Carpet Cleaning & Shampooing</a></h3>
              <p>
                We know with 5 years experience the right way to get furniture
                fresh and clean while preserving its quality of the materials.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img4" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3>
                <a href="#">General & Deep House, Pavers and Marble Cleaning</a>
              </h3>
              <p>
                Homes are usually partially cleaned and tend to accumulate alot
                of dirt but we provide a complete cleanup of the entire house
                and compound
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img5" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Liquid Soap Manufacturing</a></h3>
              <p>
                We produce highly effective and affordable liquid saop, supplied
                in quantities of 20 Litre Jerricans, 10 Litres, and 5 litre
                Jerricans. Our solution is high grad and very effective.
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img6" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Fumigation & Debuging</a></h3>
              <p>
                Get rid of choachroaches, bedbugs, spiders, flies, ticks etc
                from your house, car, farm or animals. Turn your uncormfortable
                days into peacefull calm days
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img7" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Windows Cleaning</a></h3>
              <p>
                Windows and glass surfaces generally are tricky to keep clean
                and maintain, this is beacuse glass is a brittle and fragile
                material, most people dont want to risk, but we have masterd the
                are of maintaining these materials super clean.
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img8" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Car Seats</a></h3>
              <p>
                We actually do remove the car seats outside of the veichle so
                that we totally clean up the seat thorouhly and under the car
                seats. Its very annoying to seat in a car and come out dirty.
                Lets give you and your passengers a comfirtable ride.
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img9" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">External High Level and Roof tops</a></h3>
              <p>
                We clean walls of both tall and short buildings. We have safe
                equipment that allows us to reach the hard. <br />
                Our expertise cleaning cleaning services carter for Curtain
                Classes, Concrete & Alluminium wall cladding
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img10" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">House Managers</a></h3>
              <p>
                Do you need some one professional enough and well behaved to
                take care of your home while you are not around ?. Our House
                Managers are trainned for over six months and are examined
                regularly to meet the standards that our clients require. Hire
                us today.
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img11" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3>
                <a href="#"
                  >Garden Leveling, Cleaning, Designing, Planting and
                  Maintenance</a
                >
              </h3>
              <p>
                We have staff specifically talented in
                <b>Landscaping Carftmaship</b>, Let your compounds and garden
                get that awesome look they desire to bring you satisfaction
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <div class="service-block">
            <div class="service-img">
              <a href="#"><img :src="img12" alt="" class="img-responsive" /></a>
            </div>
            <div class="service-content">
              <h3><a href="#">Sewage and Trenches</a></h3>
              <p>
                A smelling waste collection point is a nightmare to everybody
                and can be the source of many diseases. Let us do the hard dirty
                work to keep you and your people clean. Prevention is always
                better than cure.
              </p>
              <!-- <a href="#" class="btn-link"> read more</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpaceMediumOne",
  data: () => {
    return {
      img1: require("../assets/images/out_wall.png"),
      img2: require("../assets/images/photo_one.png"),
      img3: require("../assets/images/carpet.png"),
      img4: require("../assets/images/in_door.png"),
      img5: require("../assets/images/liquid.jpeg"),
      img6: require("../assets/images/fumigate.png"),
      img7: require("../assets/images/window.png"),
      img8: require("../assets/images/car_seat.png"),
      img9: require("../assets/images/roof.jpeg"),
      img10: require("../assets/images/manager.jpeg"),
      img11: require("../assets/images/garden.png"),
      img12: require("../assets/images/trench.png"),
    };
  },
};
</script>