<template>
  <div class="home">
    
    <Slider></Slider>
    <SpaceMediumOne></SpaceMediumOne>
    <SpaceMediumLight></SpaceMediumLight>
    <SpaceMediumLight2></SpaceMediumLight2>
    <CtaSection></CtaSection>
    
  </div>
</template>

<script>
import Slider from "@/components/Slider.vue";
import SpaceMediumOne from "@/components/SpaceMediumOne.vue";
import SpaceMediumLight from "@/components/SpaceMediumLight";
import SpaceMediumLight2 from "@/components/SpaceMediumLight2";
import CtaSection from "@/components/CtaSection";

export default {
  name: "Home",
  components: {
    Slider,
    SpaceMediumOne,
    SpaceMediumLight,
    SpaceMediumLight2,
    CtaSection,
  },
  mounted(){
     window.showSlider()
  }
};
</script>
