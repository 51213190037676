<template>
    <div class="contact">
    <PageHeaderContact></PageHeaderContact>
    <ContactInfo></ContactInfo>
    <ContactMap></ContactMap>
   </div>
</template>

<script>
import PageHeaderContact from '@/components/PageHeaderContact'
import ContactInfo from '@/components/ContactInfo'
import ContactMap from '@/components/ContactMap'
export default {
    name : "Contact",
    components: {
        PageHeaderContact,
        ContactInfo,
        ContactMap
    }

}
</script>