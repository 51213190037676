<template>
  <div class="space-medium bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="section-title">
            <!-- section title start-->
            <h1>Top Few Reasons to Give us a Try</h1>
            <p>
              We have been in this business for so long, the number one lesson
              we learnt at
              <br />our core, is that <b>customer satisfaction </b>is the most
              importnat thing. <br />
              Therefore we give it top priority on every assignment.
            </p>
          </div>
          <!-- /.section title start-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="feature-block mb30">
            <div class="feature-icon"><i class="icon-round68"></i></div>
            <div class="feature-content">
              <h4>Well - Trained Cleaners</h4>
              <p>
                Our staff is trained, examined and monitored for atleast three
                months before, they can start official work
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="feature-block mb30">
            <div class="feature-icon"><i class="icon-round68"></i></div>
            <div class="feature-content">
              <h4>Fast &amp; Effective Service</h4>
              <p>
                We have operational planning and strategies that allow us to
                finish our work on time.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="feature-block mb30">
            <div class="feature-icon"><i class="icon-round68"></i></div>
            <div class="feature-content">
              <h4>Quality Control</h4>
              <p>
                Every after a peice of work is finished our supervisors ensure
                that the work done is satisfactory.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="feature-block">
            <div class="feature-icon"><i class="icon-round68"></i></div>
            <div class="feature-content">
              <h4>100% Customer Care</h4>
              <p>
                We are in a peoples business, and people come first before the
                work and payment concerns. We listen carefully to customer needs
                and understand their poin of views
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="feature-block">
            <div class="feature-icon"><i class="icon-round68"></i></div>
            <div class="feature-content">
              <h4>Standard Cleaning Tools</h4>
              <p>
                They say a bad workman blames his tools, well we invest alot in
                good tooling cause we know that also the right tools get the
                work done right.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="feature-block">
            <div class="feature-icon"><i class="icon-round68"></i></div>
            <div class="feature-content">
              <h4>Over 5+ Years Of Experience</h4>
              <p>
                Experience is the best teacher, we have been through alot, over
                the years we have grown to learn how to handle a diversity of
                scenerios.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpaceMediumLight",
};
</script>