<template>
  <div class="cta-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h1>
            Have Any Questions Call us Now: +256 773 340 918, +256 700 753 406
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CtaSection",
};
</script>