<template>
  <div class="tiny-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          copyright <span id="year_copyright"></span> &copy;
          <a
            href="https://busyug.com"
            target="_blank"
            style="color: rgb(0, 255, 0)"
            >busyug.com</a
          >. All right reserved
        </div>
      </div>
      <!-- tiny-footer-start -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TinyFooter",
};
</script>