<template>
  <div class="space-medium bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="section-title">
            <!-- section title start-->
            <h1>Listen to what our clients say about us.</h1>
          </div>
          <!-- /.section title start-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="testimonial-block">
            <div class="testimonial-content">
              <p>
                “We appreciate your dedication to excellent service provided to
                our facility Monday-Friday.” <br /><br />
              </p>
            </div>
            <div class="testimonial-meta">
              <h5>James Fedralle</h5>
              <span>( Manager - Prism Construction UG LTD)</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="testimonial-block">
            <div class="testimonial-content">
              <p>
                “You are awesome! All of the cleaners have done an amazing job!
                It makes such a difference to walk into a clean home each week!”
              </p>
            </div>
            <div class="testimonial-meta">
              <h5>Keli J. Farrand</h5>
              <span>(House Wife - Nansana)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt30">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <a href="testimonies.html" class="btn btn-default btn-lg"
            >view our testimonials</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpaceMediumLight2",
};
</script>