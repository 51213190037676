<template>
  <div class="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-4 hidden-sm hidden-xs">
          <div class="social">
            <ul>
              <li>
                <a href="#"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-google-plus"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-pinterest"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 hidden-sm hidden-xs">
          <div class="call-info">
            <p class="call-text">
              <i class="fa fa-envelope-open-o"></i
              ><strong><b>beteiinvest@@gmail.com</b></strong>
            </p>
          </div>
        </div>
        <div class="col-md-5 col-sm-12">
          <div class="call-info">
            <p class="call-text">
              <i class="fa fa-phone "></i
              ><strong
                >Call Now:
                <b>0773 340 918, 0700 753 406, 0758 396 090</b></strong
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
};
</script>