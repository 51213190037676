<template>
    <div class="pricing">
        <PageHeaderPrice></PageHeaderPrice>
        <PricingContent></PricingContent>
        <CtaSection></CtaSection>
        
    </div>
</template>



<script>
import PageHeaderPrice from '@/components/PageHeaderPrice'
import PricingContent from '@/components/PricingContent'
import CtaSection from '@/components/CtaSection'

export default {
    name : "Pricing",
    components: {
      PageHeaderPrice,
      PricingContent,
      CtaSection

    }
}
</script>