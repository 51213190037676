<template>
    <div class="space-medium">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="section-title">
                        <!-- section title start-->
                        <h1>Meet our Cleaning Staff</h1>
                        <p>Duis sollicitudin hendrerit bibendum. Phasellus interdum, nisi vel elementum convallis
                            <br> urna turpis commodo velit, ac pulvinar quam magna sed orci.</p>
                    </div>
                    <!-- /.section title start-->
                </div>
            </div>
            <div class="row">
                <!-- member-1-start -->
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="team-block">
                        <div class="team-img imghover"> <img :src="staff1" alt="" class="img-responsive">
                        </div>
                        <div class="team-content mt20">
                            <h3 class="team-title">Merry Tanscom</h3>
                        </div>
                    </div>
                </div>
                <!-- member-1-close -->
                <!-- member-2-start -->
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="team-block">
                        <div class="team-img imghover"> <img :src="staff2" alt="" class="img-responsive">
                        </div>
                        <div class="team-content mt20">
                            <h3 class="team-title">Lesley Dingle</h3>
                        </div>
                    </div>
                </div>
                <!-- member-2-close -->
                <!-- member-3-start -->
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="team-block">
                        <div class=" team-img imghover"> <img :src="staff3" alt="" class="img-responsive">
                        </div>
                        <div class="team-content mt20">
                            <h3 class="team-title">James Fipher</h3>
                        </div>
                    </div>
                </div>
                <!-- member-3-close -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "Staff",
    data: () =>{
      return {
          staff1: require('@/assets/images/team-member-1.jpg'),
          staff2: require('@/assets/images/team-member-2.jpg'),
          staff3: require('@/assets/images/team-member-3.jpg')

        
      }
}
}
</script>