<template>
    <div class="space-medium">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="contact-block">
                        <div class="contact-info">
                            <i class="fa fa-map-marker"></i>
                            <h4 class="contact-info-title">Our Address</h4>
                            <p>1250 Mark Avenue california, - USA</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="contact-block">
                        <div class="contact-info">
                            <i class="fa fa-phone"></i>
                            <h4 class="contact-info-title">Call Now</h4>
                            <p><strong class="text-primary">+1800-123-4567</strong></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="contact-block">
                        <div class="contact-info">
                            <i class="fa fa-envelope-open"></i>
                            <h4 class="contact-info-title">Email us</h4>
                            <p>info@shinecleaningservice.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="mb30">
                        <div class="contact-form">
                            <h1>Get In Touch</h1>
                            <p class="mb40">Drop us a line via the contact form. We will do our best to reply as soon as possible. </p>
                            <div class="row">
                                <form>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label sr-only " for="name"></label>
                                            <input id="name" type="text" placeholder="Name" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label sr-only " for="email"></label>
                                            <input id="email" type="text" placeholder="Email" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label sr-only " for="phone"></label>
                                            <input id="phone" type="text" placeholder="phone" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                <label class="control-label sr-only required" for="Cleaning"> </label>
                                <select id="cleaning" name="Cleaning services" placeholder="Cleaning Services" type="text" class="form-control">
                                    <option value="">Cleaning Services</option>
                                    <option value="1">Home Cleaning</option>
                                    <option value="2">Office Cleaning</option>
                                    <option value="3">Commercial Cleaning</option>
                                   
                                </select>
                            </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label sr-only" for="textarea"></label>
                                            <textarea class="form-control" id="textarea" name="textarea" rows="4" placeholder="Messages"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <button class="btn btn-primary">send us message</button>
                                    </div>
                            </form></div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
export default {
    name : "ContactInfo"
}
</script>