<template>
    <div class="content">
        <div class="container">
            
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="price-head">
                        <h3> House Cleaning</h3>
                    </div>
                    <div class="price-block">
                        <div class="price-content">
                            <h4>Kitchen &amp; Bedrooms</h4>
                            <div class="price-text">
                                <p>$28/<small style="font-size: 10px;"> hour</small></p>
                            </div>
                            <ul>
                                <li>Wipe Cabinets</li>
                                <li>Wipes Tops</li>
                                <li>Dust Tops of Cabinets</li>
                            </ul>
                        </div>
                        <div class="price-content">
                            <h4>Bathroom</h4>
                            <div class="price-text">
                                <p>$29/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul>
                                <li>Dust Counters</li>
                                <li>Dust Light</li>
                                <li>Wipe Countertops</li>
                            </ul>
                        </div>
                        <div class="price-content">
                            <h4>Living Room</h4>
                            <div class="price-text">
                                <p>$29/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul>
                                <li>Make Beds</li>
                                <li>Dust Furniture</li>
                                <li>Dust Mini-blinds</li>
                            </ul>
                        </div>
                        <div class="text-center mt30"><a href="#" class="btn btn-primary btn-sm">book your order now</a></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="price-head">
                        <h3>Office Cleaning</h3>
                    </div>
                    <div class="price-block">
                        <div class="price-content">
                            <h4>Once a Week</h4>
                            <div class="price-text">
                                <p>$270/<small style="font-size: 10px;"> hour</small></p>
                            </div>
                            <ul>
                                <li>( 4 Sessions - 3 hrs per Session )</li>
                                
                            </ul>
                        </div>
                        <div class="price-content">
                            <h4>Twice a Week</h4>
                            <div class="price-text">
                                <p>$574/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul>
                                <li>( 8 Sessions - 3 hrs per Session )</li>
                                </ul>
                        </div>
                        <div class="price-content">
                            <h4>Thrice a Week</h4>
                            <div class="price-text">
                                <p>$670/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul>
                                <li>( 12 Sessions - 3 hrs per Session )</li>
                                
                            </ul>
                        </div>
                        <div class="price-content">
                            <h4>Mon - Fri Daily</h4>
                            <div class="price-text">
                                <p>$990/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul>
                                <li>( 20 Sessions - 2 hrs per Session )</li>
                                
                            </ul>
                        </div>
                        <div class="text-center mt30"><a href="#" class="btn btn-primary btn-sm">book your order now</a></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="price-head">
                        <h3>Commercial Cleaning</h3>
                    </div>
                    <div class="price-block">
                        <div class="price-content">
                            <h4>Kitchen &amp; Bedrooms</h4>
                            <div class="price-text">
                                <p>$28/<small style="font-size: 10px;"> hour</small></p>
                            </div>
                            <ul>
                                <li>Wipe Cabinets</li>
                                <li>Wipes Tops</li>
                                <li>Dust Tops of Cabinets</li>
                            </ul>
                        </div>
                        <div class="price-content">
                            <h4>Bathroom</h4>
                            <div class="price-text">
                                <p>$29/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul>
                                <li>Dust Counters</li>
                                <li>Dust Light</li>
                                <li>Wipe Countertops</li>
                            </ul>
                        </div>
                        <div class="price-content">
                            <h4>Living Room</h4>
                            <div class="price-text">
                                <p>$29/ <small style="font-size: 10px;">hour</small></p>
                            </div>
                            <ul><li>Make Beds</li>
                                <li>Dust Furniture</li>
                                <li>Dust Mini-blinds</li>
                            </ul>
                        </div>
                        <div class="text-center mt30"><a href="#" class="btn btn-primary btn-sm">book your order now</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PricingContent"
}
</script>