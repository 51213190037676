<template>
  <div class="about">
    
     <PageHero></PageHero>
     <AboutusInfo></AboutusInfo>
     <Accomplishments></Accomplishments>
     <Reasons></Reasons>
     <Staff></Staff>
     
     <CtaSection></CtaSection>
     

    
  </div>
</template>



<script>
import PageHero from '@/components/PageHero'
import AboutusInfo from '@/components/AboutusInfo'
import Accomplishments from '@/components/Accomplishments'
import Reasons from '@/components/Reasons'
import Staff from '@/components/Staff'
import CtaSection from '@/components/CtaSection'



export default {
  name: 'About',
  components: {
        
        
        CtaSection,
        PageHero,
        AboutusInfo,
        Accomplishments,
        Reasons,
        Staff
        


    }


}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
