<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <!-- footer-contactinfo-start -->
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="footer-widget">
            <h3 class="footer-title">About Cleaning</h3>
            <p>
              Non ultricies enim ut a accumsan a nullam dapibus rhoncus
              vehicular sed orci lorem est consectetur sam orci.
            </p>
            <div class="">
              <ul>
                <li>
                  <div class=""><i class="fa fa-map-marker"></i></div>
                  <div class="footer-address">
                    Nansana Opposite JJ Medical Centre
                  </div>
                </li>
                <li>
                  <div class=""><i class="fa fa-envelope-open"></i></div>
                  <div class="footer-address">beteiinvest@gmail.com</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- footer-contactinfo-close -->
        <!-- footer-useful links-start -->
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div class="footer-widget">
            <h3 class="footer-title">Our Services</h3>
            <ul>
              <li><a href="#">Liquid Saop Manufacturing</a></li>
              <li><a href="#">Factory, Complex, Office & Buildings</a></li>
              <li><a href="#">Contract Cleaning</a></li>
              <li><a href="#">Detailed Cleaning</a></li>
              <li><a href="#">Sofa Set Cleaning</a></li>
              <li><a href="#">Carpet Cleaning & Shampooing</a></li>
              <li><a href="#">Sewage</a></li>
              <li><a href="#">Windows Cleaning</a></li>
              <li><a href="#">Fumigation</a></li>
              <li><a href="#">Car Seats</a></li>
              <li><a href="#">General House Cleaning</a></li>
              <li><a href="#">External High Level</a></li>
            </ul>
          </div>
        </div>
        <!-- footer-useful links-close -->
        <!-- footer-useful links-start -->
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
          <div class="footer-widget">
            <h3 class="footer-title">Quick Links</h3>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us </a></li>
              <!-- <li><a href="#">Blog</a></li> -->
              <li><a href="/pricing">Pricing </a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>
        </div>
        <!-- footer-useful links-close -->
        <!-- footer-about-start -->
        <div class="col-lg-2 col-md-3 col-sm-2 col-xs-12">
          <div class="footer-widget">
            <h3 class="footer-title">Share With us</h3>
            <div class="footer-social">
              <a href="#"
                ><span><i class="fa fa-facebook"></i></span
              ></a>
              <a href="#"
                ><span><i class="fa fa-google-plus"></i></span>
              </a>
              <a href="#"
                ><span class="active"><i class="fa fa-twitter"></i> </span
              ></a>
              <a href="#"
                ><span><i class="fa fa-pinterest"></i> </span
              ></a>
              <a href="#"
                ><span><i class="fa fa-linkedin"></i></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>