<template>
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                    <img :src="imgabout" alt="" class="img-responsive">
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                    <div class="">
                        <h1>We're ready to clean up your <br>home at anytime!</h1>
                        <p class="lead">We're basically the best! We're prepared to tidy up your home, office, house with a shocking and intense accuracy.</p>
                        <p>Duis sollicitudin hendrerit bibendum. Phasellus interdum, nisi vel elementum convallis, urna turpis commodo velitac pulvinar quam magna sitae hen Maecenas dignissim tortor id varius sodales. </p>
                        <a href="/contact" class="btn btn-default btn-lg">contact now</a>
                    </div>
                </div>
            </div>
        </div>
  
</template>

<script>
export default {
    name: "AboutusInfo",
data: () =>{
      return {
          imgabout: require('@/assets/images/about-pic.jpg')
        
      }
}
};
</script>