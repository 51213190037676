<template>
    <div class="space-medium bg-light">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="section-title">
                        <!-- section title start-->
                        <h1>Top Few Reasons to Give us a Try</h1>
                        <p>Feugiat etiam ut justo ut sem molestie viverra id act massa pellentesque non
                            <br>tellus urna donc orci nulla erat orci consequat.</p>
                    </div>
                    <!-- /.section title start-->
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="feature-block mb30">
                        <div class="feature-icon"><i class="icon-round68"></i> </div>
                        <div class="feature-content">
                            <h4>Well - Trained Cleaners</h4>
                            <p>Molestie viverra id act massa pellentesque non tellus urna donc orci consequat. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="feature-block  mb30">
                        <div class="feature-icon"><i class="icon-round68"></i> </div>
                        <div class="feature-content">
                            <h4>Fast &amp; Effective Service</h4>
                            <p>Feugiat etiam ut justo ut sem molestie vellus urna donc orci nulla erat orci consequat. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="feature-block mb30">
                        <div class="feature-icon"><i class="icon-round68"></i> </div>
                        <div class="feature-content">
                            <h4>Quality Control</h4>
                            <p>Feugiat etiam ut justo ut sem molestie vellus urna donc orci nulla erat orci consequat. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="feature-block">
                        <div class="feature-icon"><i class="icon-round68"></i> </div>
                        <div class="feature-content">
                            <h4>100% Quality Guarentee</h4>
                            <p>Molestie viverra id act massa pellentesque non tellus urna donc orci consequat. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="feature-block">
                        <div class="feature-icon"><i class="icon-round68"></i> </div>
                        <div class="feature-content">
                            <h4>Standard Cleaning Tools</h4>
                            <p>Feugiat etiam ut justo ut sem molestie vellus urna donc orci nulla erat orci consequat. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="feature-block">
                        <div class="feature-icon"><i class="icon-round68"></i> </div>
                        <div class="feature-content">
                            <h4>Daily Task List</h4>
                            <p>Cras commodo ligula a urna egestas porta dui porta bibendum urna at semper. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "Reasons"
}
</script>