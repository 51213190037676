<template>
    <div class="page-header ph-bg" :style = "{backgroundImage: 'url(' + require('@/assets/images/page-header.jpg') + ')'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div class="page-section">
                        <h1 class="page-title">Pricing</h1>
                        <p class="page-text">Our prices for cleaning &amp; maid services are very affordable and are based on hourly cleaning service fees. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pageHeaderPrice"
}
</script>